import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  settings: null,
  isAdministrator: JSON.parse(localStorage.getItem('isAdministrator')) ?? false,
};

export const SettingsSlice = createSlice({
  name: 'SettingsSlice',
  initialState,
  reducers: {
    setSettings: (state, action) => {
      state.settings = action.payload;
    },
    setAdministrator: (state, action) => {
      const administrator = action.payload?.some((group) => group?.Name === 'Administrators');
      state.isAdministrator = administrator;
      localStorage.setItem('isAdministrator', administrator);
    },
  },
});

export const { setSettings, setAdministrator } = SettingsSlice.actions;

export default SettingsSlice.reducer;
