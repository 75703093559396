import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  systemTools: [],
  customTools: [],
  categoriesTools: [],
  activeRerender: false,
  settingsRerender: false,
  selectedPrompt: JSON.parse(localStorage.getItem('search')),
  chatSearchTitle: '',
  selectedCategory: JSON.parse(localStorage.getItem('selectedCategory')),
  selectedFile: JSON.parse(localStorage.getItem('selectedFile')),
  agGridLoader: true,
  paginationPageData: {},
};

export const MasterSlice = createSlice({
  name: 'MasterSlice',
  initialState,
  reducers: {
    setSystemTools: (state, action) => {
      state.systemTools = action.payload;
    },
    setCustomTools: (state, action) => {
      state.customTools = action.payload;
    },
    setCategoriesTools: (state, action) => {
      state.categoriesTools = action.payload;
    },
    setActiveRerender: (state) => {
      state.activeRerender = !state.activeRerender;
    },
    setSettingsRerender: (state) => {
      state.settingsRerender = !state.settingsRerender;
    },
    setSelectedPrompt: (state, action) => {
      state.selectedPrompt = action.payload;
    },
    setChatSearchTitle: (state, action) => {
      state.chatSearchTitle = action.payload;
    },
    setSelectedCategory: (state, action) => {
      state.selectedCategory = action.payload;
    },
    setSelectedFile: (state, action) => {
      state.selectedFile = action.payload;
    },
    setAgGridLoader: (state, action) => {
      state.agGridLoader = action.payload;
    },
    setPaginationPageData: (state, action) => {
      state.paginationPageData = action.payload;
    },
  },
});

export const {
  setActiveRerender,
  setSettingsRerender,
  setSystemTools,
  setCustomTools,
  setCategoriesTools,
  setSelectedPrompt,
  setChatSearchTitle,
  setSelectedCategory,
  setAgGridLoader,
  setPaginationPageData,
  setSelectedFile,
} = MasterSlice.actions;

export default MasterSlice.reducer;
