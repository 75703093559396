import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  userData: null,
};

export const UsersSlice = createSlice({
  name: 'UsersSlice',
  initialState,
  reducers: {
    setUserData: (state, action) => {
      state.userData = { ...state.userData, ...action.payload };
    },
  },
});

export const { setUserData } = UsersSlice.actions;

export default UsersSlice.reducer;
