import { lazy } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';

const DashboardLayout = lazy(() => import('./layouts/dashboard'));
const SimpleLayout = lazy(() => import('./layouts/simple'));
const AuthGuard = lazy(() => import('./auth/AuthGuard'));
const Home = lazy(() => import('./pages/Home'));
const ReportPage = lazy(() => import('./pages/ReportPage'));
const Page404 = lazy(() => import('./pages/Page404'));
const LoginPage = lazy(() => import('./pages/LoginPage'));
const SSOValidation = lazy(() => import('./pages/SSOValidation'));
const Register = lazy(() => import('./pages/Register'));
const ForgotPage = lazy(() => import('./pages/ForgotPage'));

export default function Router({ isDarkMode, handleThemeChange, textSize, setTextSize }) {
  const routes = useRoutes([
    {
      path: '/',
      element: (
        <AuthGuard handleThemeChange={handleThemeChange}>
          <DashboardLayout
            handleThemeChange={handleThemeChange}
            isDarkMode={isDarkMode}
            textSize={textSize}
            setTextSize={setTextSize}
          />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to="/home" />, index: true },
        { path: 'home', element: <Home /> },
        { path: 'report', element: <ReportPage /> },
      ],
    },
    {
      path: 'forgot-password',
      element: <ForgotPage />,
    },
    {
      path: 'auth-response',
      element: <SSOValidation />,
    },
    {
      path: 'login',
      element: <LoginPage />,
    },
    {
      path: 'signup',
      element: <Register />,
    },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/home" />, index: true },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
